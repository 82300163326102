export class Country {

    public id: number;

    public name: string;

    public ISOCode: string;

    public OrangeMCOCode: string;

    public currency: string;

    public lang: any;

    public isActive: boolean;

    public constructor (){

    }
}
