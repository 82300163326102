import { Component, OnInit, ViewChild, ElementRef, ɵisListLikeIterable, HostListener } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ProductService } from '../product.service';
import { EditorService } from '../../editor/editor.service';
import { Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidationErrors, } from '@angular/forms';
import { faTimes, faArrowAltCircleLeft, faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-product-new',
  templateUrl: './product-new.component.html'
})
export class ProductNewComponent implements OnInit {

  //icons
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faTimes = faTimes;
  faCheck = faCheck;


  editors = [];
  types = [];
  recurrences = [];
  prefix = undefined;
  errorReference = false;
  wait = 0;
  params = undefined;

  form: FormGroup;
  TYPE_VOUCHER;
  TYPE_ONE_TIME_SUBSCRIPTION;
  TYPE_RECURRING_SUBSCRIPTION;
  TYPE_RECURRING_PAYMENT;

  @HostListener('click', ['$event.target']) onClick(target: EventTarget) {
    if (target === this.kartiModal.nativeElement) {
      this.hideKartiModal();
    }
  }

  @ViewChild('kartiModal', { static: false }) kartiModal: ElementRef<HTMLDivElement>;
  kartiLoading = false;
  kartiResponse = null;

  constructor(private productService: ProductService, private editorService: EditorService, private _router: Router, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      editor: new FormControl('',[Validators.required]),
      reference: new FormControl('', [
        Validators.pattern("^[a-zA-Z0-9_-]{1,250}$"), Validators.required
      ]),
      label: new FormControl(''),
      type: new FormControl('',[Validators.required]),
      active: new FormControl('', [Validators.required]),
      recurrence: new FormControl('',[Validators.required]),
      faceValue: new FormControl('',[Validators.required]),
      faceUnit: new FormControl('',[Validators.required]),
      oneProductOnly: new FormControl('', [Validators.required]),
      kartiProductId: new FormControl('', []),
    });
    this.TYPE_RECURRING_PAYMENT = productService.TYPE_RECURRING_PAYMENT;
    this.TYPE_RECURRING_SUBSCRIPTION = productService.TYPE_RECURRING_SUBSCRIPTION;
    this.TYPE_VOUCHER = productService.TYPE_VOUCHER;
    this.TYPE_ONE_TIME_SUBSCRIPTION = productService.TYPE_ONE_TIME_SUBSCRIPTION;
  }

  ngOnInit() {
    this.getEditors();
    this.getTypes();
    this.getRecurrences();
  }

  updateValidators() {
    const faceValue = this.form.get('faceValue');
    const faceUnit = this.form.get('faceUnit');
    const recurrence = this.form.get('recurrence');
    const type = this.form.get('type').value;

    if (type === this.TYPE_ONE_TIME_SUBSCRIPTION || type === this.TYPE_RECURRING_PAYMENT || type === this.TYPE_RECURRING_SUBSCRIPTION) {
      faceValue.setValidators(null);
      faceUnit.setValidators(null);
      recurrence.setValidators([Validators.required]);
    }

    if (type === this.TYPE_VOUCHER) {
      faceValue.setValidators([Validators.required]);
      faceUnit.setValidators([Validators.required]);
      recurrence.setValidators(null);
    }

    recurrence.updateValueAndValidity();
    faceValue.updateValueAndValidity();
    faceUnit.updateValueAndValidity();
  }

  getEditors() {
    var params = new HttpParams().set('isActive', "true");

    this.editorService.getEditors(params).subscribe(
        data => {
          this.editors = data.details;
          this.wait = this.wait + 1;
        },
        error => { console.log(error); },
        () => { }
    );
  }

  getTypes() {
    this.productService.getProductsTypes().subscribe(
        data => {
          this.types = data.details;
          this.wait = this.wait + 1;
        },
        error => { console.log(error); },
        () => { }
    );
  }

  getRecurrences() {
    this.productService.getProductsSubscriptionRecurrences().subscribe(
        data => {
          this.recurrences = data.details;
          this.wait = this.wait + 1;
        },
        error => { console.log(error); },
        () => { }
    );
  }

  addPrefixReference() {
    var values = this.form.value;
    var prefix = this.editors[values.editor]['reference'] + '_';
    if ((values.reference.length - prefix.length) > 5) {
      this.errorReference = true;
    } else {
      this.errorReference = false;
    }
    if (values.reference != undefined) {
      if (values.reference.indexOf(prefix) == -1) {
        this.form.patchValue({ 'reference': prefix });
      } else if (values.reference.indexOf(prefix) != 0) {
        this.form.patchValue({ 'reference': prefix + values.reference });
      }
    } else {
      values.reference = prefix;
    }
  }

  submitForm() {
    this.form.controls.reference.markAsDirty();
    this.updateValidators();
    if(this.form.valid){
      var values = this.form.value;
      var editor = this.editors[values.editor]['id'];
      this.params = {
        'editor': editor,
        'reference': values.reference,
        'label': values.label,
        'type': values.type,
        'recurrence': values.type === this.TYPE_VOUCHER ? values.recurrence = null : values.recurrence ,
        'face_value': values.type !== this.TYPE_VOUCHER ? values.faceValue = "0.00" : values.faceValue,
        'face_unit': values.type !== this.TYPE_VOUCHER ? values.faceUnit = null : values.faceUnit,
        'is_active': values.active,
        'one_product_only': values.oneProductOnly,
        'karti_product_id': values.kartiProductId,
      };
      
      if (values.kartiProductId) {
        this.kartiLoading = true;
        this.showKartiModal();

        this.productService
          .getKartiProduct(values.kartiProductId, editor)
          .subscribe(
            (data) => {
              this.hideKartiModal();
              this.kartiLoading = false;
              this.kartiResponse = data;
              this.showKartiModal();
            },
            (err) => {
              this.hideKartiModal();
              console.log(err);
            },
          );
      } else {
        this.createProduct();
      }
    }
  }

  createProduct() {
    this.productService.postNewProduct(this.params).subscribe(
      data => {
        this._router.navigate(['/product/' + data.details.id])
      },
      error => {
        this.hideKartiModal();
        console.log(error);
      },
    );
  }

  hideKartiModal() {
    this.kartiResponse = null;
    this.kartiModal.nativeElement.style.display = 'none';
  }

  showKartiModal() {
    this.kartiModal.nativeElement.style.display = 'block';
  }
}
