import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PriceService } from '../price.service';
import { DatePipe } from '@angular/common';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-price-list',
  styleUrls: ["./price-list.component.css"],
  templateUrl: './price-list.component.html'
})
export class PriceListComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('countryFilter', { static: false }) countryFilter: ElementRef;
  @ViewChild('distributionSiteFilter', { static: false }) distributionSiteFilter: ElementRef;
  @ViewChild('currencyFilter', { static: false }) currencyFilter: ElementRef;
  @ViewChild('distributableProductRefFilter', { static: false }) distributableProductRefFilter: ElementRef;
  @ViewChild('productNameFilter', { static: false }) productNameFilter: ElementRef;
  @ViewChild('preTaxValueFilter', { static: false }) preTaxValueFilter: ElementRef;
  @ViewChild('inclusiveTaxValueFilter', { static: false }) inclusiveTaxValueFilter: ElementRef;
  @ViewChild('inclusiveTaxValueEURFilter', { static: false }) inclusiveTaxValueEURFilter: ElementRef;
  @ViewChild('promoPreTaxValueFilter', { static: false }) promoPreTaxValueFilter: ElementRef;  
  @ViewChild('promoInclusiveTaxValueFilter', { static: false }) promoInclusiveTaxValueFilter: ElementRef;  
  @ViewChild('promoInclusiveTaxValueEURFilter', { static: false }) promoInclusiveTaxValueEURFilter: ElementRef; 
  @ViewChild('soldValueFilter', { static: false }) soldValueFilter: ElementRef;

  filters = [];
  datas = [];
  temp = [];
  loading: boolean = true;

  country = [];
  distributionSite = [];

  constructor(private priceService: PriceService, public datepipe: DatePipe, public fileSaverService: FileSaverService) { }

  ngOnInit() {
    this.getPrices();
  }

  getPrices() {
    this.priceService.getActivePrices().subscribe((data:any) => {
      this.datas = data.details;
      this.temp = [...data.details];
      this.fillSelects();
      this.loading = false;
      setTimeout(() => {
        this.triggerChangeCountry();
      }, 150);
    }, (error) => {
      this.loading = false;
    });
  }

  triggerChangeCountry() {
    this.countryFilter.nativeElement.value = this.country[0];
    (this.countryFilter.nativeElement as HTMLElement).dispatchEvent(new Event("change"));
    setTimeout(() => {
      this.triggerChangeDistributionSite();
    }, 150);
  }

  triggerChangeDistributionSite() {
    this.distributionSiteFilter.nativeElement.value = this.distributionSite[0];
    (this.distributionSiteFilter.nativeElement as HTMLElement).dispatchEvent(new Event("change"));
  }

  calculatePreTaxValue (row) {
    let index = this.datas.findIndex(x => x.distributable_product_id === row.distributable_product_id);

    var VATRate = this.datas[index].price_tax_rate;
    var inclusiveTaxValue = this.datas[index].inclusive_tax_value;
    this.datas[index].pre_tax_value = (inclusiveTaxValue / (1 + (VATRate) / 100)).toFixed(2);
    this.datas[index].inclusive_tax_value_EUR = (inclusiveTaxValue * this.datas[index].rate_EUR).toFixed(2);

    if (null === inclusiveTaxValue) {
      this.datas[index].pre_tax_value = null;
      this.datas[index].inclusive_tax_value_EUR = null;
    } else {
      this.datas[index].pre_tax_value = (inclusiveTaxValue / (1 + (VATRate) / 100)).toFixed(2);
      this.datas[index].inclusive_tax_value_EUR = (inclusiveTaxValue * this.datas[index].rate_EUR).toFixed(2);
    }
  }

  calculatePromoPreTaxValue (row) {
    let index = this.datas.findIndex(x => x.distributable_product_id === row.distributable_product_id);

    var VATRate = this.datas[index].price_tax_rate;
    var promoInclusiveTaxValue = this.datas[index].promo_inclusive_tax_value;

    if (null === promoInclusiveTaxValue) {
      this.datas[index].promo_pre_tax_value = null;
      this.datas[index].promo_inclusive_tax_value_EUR = null;
    } else {
      this.datas[index].promo_pre_tax_value = (promoInclusiveTaxValue / (1 + (VATRate) / 100)).toFixed(2);
      this.datas[index].promo_inclusive_tax_value_EUR = (promoInclusiveTaxValue * this.datas[index].rate_EUR).toFixed(2);
    }
  }

  fillSelects() {
    this.country = this.datas
        .map(item => item.country_name)
        .filter((value, index, self) => self.indexOf(value) === index);
  }

  exportPrices() {
    var params = '';
    this.filters.forEach((element, index) => {
        if (index === 0)
          params += '?';
        else {
          params += '&';
        }
        params += element.key + '=' + element.value;
    });

    this.priceService.exportActivePrices(params).subscribe(
      data => {
        var filename = "Export Prices OLink agg " + this.datepipe.transform(new Date(), 'ddMMyyyy');
        this.fileSaverService.save(data, filename + '.csv');
      },
      error => { console.log(error); },
      () => { }
    );
  }

  onCountryChange(event, prop) {
    const value = event.target.value;

    this.distributionSite = this.temp
      .filter((item) => item.country_name === value)
      .map(item => item.distribution_site_name)
      .filter((value, index, self) => self.indexOf(value) === index);
    
    setTimeout(() => {
      this.triggerChangeDistributionSite();
    }, 150);

    this.updateFilter(event, prop);
  }

  updateFilter(event, prop) {
    const existingIndex = this.filters.findIndex(item => item.key === prop);
    const value = event.target.value;

    if (value !== '') {
      if (existingIndex !== -1) {
        this.filters[existingIndex].value = value;
      } else {
        this.filters.push({'key': prop, 'value': value, 'type': ''});
      }
    } else {
      if (existingIndex !== -1) {
        this.filters.splice(existingIndex, 1);
      }
    }

    const temp = this.temp.filter(rows => {
      return this.filters.every(filter => {
        if (rows.hasOwnProperty(filter.key) && rows[filter.key] !== null) {
          return rows[filter.key].toString().toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 || !filter.value;
        } else {
          return !filter.value;
        }
      });
    });
    
    this.datas = temp;
    this.table.offset = 0;
  }

  resetFilter() {
    this.countryFilter.nativeElement.value = '';
    this.distributionSiteFilter.nativeElement.value = '';
    this.currencyFilter.nativeElement.value = '';
    this.distributableProductRefFilter.nativeElement.value = '';
    this.productNameFilter.nativeElement.value = '';
    this.preTaxValueFilter.nativeElement.value = '';
    this.inclusiveTaxValueFilter.nativeElement.value = '';
    this.inclusiveTaxValueEURFilter.nativeElement.value = '';
    this.promoPreTaxValueFilter.nativeElement.value = '';
    this.promoInclusiveTaxValueFilter.nativeElement.value = '';
    this.promoInclusiveTaxValueEURFilter.nativeElement.value = '';
    this.soldValueFilter.nativeElement.value = '';
  }

  clearFilters() {
    this.resetFilter();
    this.datas = this.temp;
    this.filters = [];
    this.table.offset = 0;
    this.triggerChangeCountry();
  }

  save() {
    this.loading = true;
    this.priceService.putPrices(this.datas).subscribe((data) => {
      this.datas = data.details;
      this.temp = [...data.details];
      this.loading = false;
      setTimeout(() => {
        this.triggerChangeCountry();
      }, 150);
    }, (error) => {
      console.log(error);
      this.loading = false;
    })
  }
}
