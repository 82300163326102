import { Component, OnInit, ViewChild, ElementRef, asNativeElements } from '@angular/core';
import { CountryService } from '../country.service';
import { Country } from '../Country';


@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
})

export class CountryListComponent implements OnInit {

  @ViewChild('nameFilter', { static: false }) nameFilter: ElementRef;
  @ViewChild('isActiveFilter', { static: false }) isActiveFilter: ElementRef;
  @ViewChild('currencyFilter', { static: false }) currencyFilter: ElementRef;

  loading: boolean = true;
  error: string = null;
  countries = [];
  filteredCountries = [];

  // Item number per page
  countriesPerPage: number = 5;

  ngOnInit(): void {
    this.getCountries();
  }

  constructor(
    private countryService: CountryService,
  ) { }

  getCountries() {
    this.countryService.getCountries().subscribe(data => {
      this.loading = false;
      this.error = null;
      this.countries = data.details;
      this.filteredCountries = data.details;
    },
      error => {
        this.error = error.statusText;
        this.loading = false;
      });
  }

  changenCountriesPerPage(value) {
    this.countriesPerPage = value;
  }

  updateFilter() {
    this.filteredCountries = this.countries
      .filter(country => {
        return this.filterValue(country.name, this.nameFilter);
      })
      .filter(country => {
        return this.filterValue(country.currency, this.currencyFilter);
      })
      .filter(country => {
        return this.filterValue(country.isActive + '', this.isActiveFilter);
      })
  }

  clearFilters() {
    this.nameFilter.nativeElement.value = '';
    this.currencyFilter.nativeElement.value = '';
    this.isActiveFilter.nativeElement.value = '';
    this.updateFilter();
  }

  filterValue(parameter = '', criteria: ElementRef) {
    return criteria === undefined ? true : criteria.nativeElement.value === '' || parameter.match(new RegExp(criteria.nativeElement.value, 'i'))
  }

}
