import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  getCountries(params = new HttpParams()): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'countries?' + params.toString(), { headers: this.httpHeaders });
  }

  getCountryById(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'country/' + id, { headers: this.httpHeaders });
  }

  updateCountry(param): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'country/' + param.id, param, { headers: this.httpHeaders });
  }

  createCountry(param): Observable<any> {
    return this.http.post<any>(environment.ApiUrl + 'country', param, { headers: this.httpHeaders });
  }

  getLangs(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'langs', { headers: this.httpHeaders });
  }
}
